var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"page-layout"},[_c('gc-header',{attrs:{"headerData":"waterMeterMonitor"},scopedSlots:_vm._u([{key:"ops",fn:function(){return [_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleExport}},[_vm._v("导 出")])]},proxy:true}])}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},[_c('GcRegionTree',{attrs:{"initSelect":false},model:{value:(_vm.form.waterRegionCode),callback:function ($$v) {_vm.$set(_vm.form, "waterRegionCode", $$v)},expression:"form.waterRegionCode"}})],1),_c('div',{staticClass:"run-main"},[_c('div',{staticClass:"serach"},[_c('gc-custom-search',{attrs:{"keyWord":"用户号","width":"300px","search":_vm.form.userNo},on:{"update:search":function($event){return _vm.$set(_vm.form, "userNo", $event)}}}),_c('gc-custom-search',{attrs:{"keyWord":"客户名称","width":"300px","search":_vm.form.userName},on:{"update:search":function($event){return _vm.$set(_vm.form, "userName", $event)}}}),_c('gc-custom-search',{attrs:{"keyWord":"设备编号","width":"300px","search":_vm.form.deviceNo},on:{"update:search":function($event){return _vm.$set(_vm.form, "deviceNo", $event)}}}),_c('gc-custom-search',{attrs:{"keyWord":"设备类型","width":"300px","type":"select","search":_vm.form.deviceTypeId,"searchOption":_vm.options.deviceTypeId,"search-option-key":{
            label: 'deviceTypeName',
            value: 'deviceTypeId',
          },"needAllForSearch":true},on:{"update:search":function($event){return _vm.$set(_vm.form, "deviceTypeId", $event)}}}),_c('gc-custom-search',{attrs:{"keyWord":"厂商","width":"300px","type":"select","search":_vm.form.manufacturerId,"searchOption":_vm.options.manufacturerId,"needAllForSearch":true},on:{"update:search":function($event){return _vm.$set(_vm.form, "manufacturerId", $event)}}}),_c('gc-custom-search',{attrs:{"keyWord":"地址","width":"300px","search":_vm.form.address},on:{"update:search":function($event){return _vm.$set(_vm.form, "address", $event)}}}),_c('gc-custom-search',{attrs:{"keyWord":"手机号","width":"300px","search":_vm.form.phone},on:{"update:search":function($event){return _vm.$set(_vm.form, "phone", $event)}}}),_c('gc-custom-search',{attrs:{"keyWord":"阀门状态","width":"300px","type":"select","search":_vm.form.valveStatus,"searchOption":_vm.options.valveStatus,"needAllForSearch":true},on:{"update:search":function($event){return _vm.$set(_vm.form, "valveStatus", $event)}}}),_c('gc-custom-search',{attrs:{"keyWord":"未上传天数","type":"input-number","width":"300px","keyWordWidth":"86px","search":_vm.form.notUpLoadDay},on:{"update:search":function($event){return _vm.$set(_vm.form, "notUpLoadDay", $event)}}}),_c('div',{staticClass:"serach-ops"},[_c('el-button',{directives:[{name:"click-blur",rawName:"v-click-blur"}],attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1, _vm.apiWaterMetrologicalMonitorList, null)}}},[_vm._v("查 询")]),_c('el-button',{directives:[{name:"click-blur",rawName:"v-click-blur"}],on:{"click":function($event){return _vm.reset(_vm.apiWaterMetrologicalMonitorList, null)}}},[_vm._v("重 置")])],1)],1),_c('div',{staticClass:"table-show"},[_c('gc-table',{attrs:{"columns":_vm.columns,"table-data":_vm.tableData,"border":true,"showPage":true,"total":_vm.page.total,"page-size":_vm.page.size,"currentPage":_vm.page.current},on:{"current-page-change":function (currentPage) {
              _vm.currentPageChange(
                currentPage,
                _vm.apiWaterMetrologicalMonitorList,
                null
              );
            }}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }